<template>
  <div class="karakutu-container">
    <div id="blurry-filter"></div>
    <AppHeader></AppHeader>
    <main class="karakutu-content">
      <slot></slot>
    </main>
    <ScrollTopButton />
    <AppFooter v-if="!isLoading"></AppFooter>
  </div>
</template>

<script setup>
  import AppFooter from "../components/AppFooter.vue";
  import AppHeader from "../components/AppHeader.vue";
  import ScrollTopButton from "../components/ScrollTopButton.vue";
  import { computed } from 'vue';
  import { useUserStore } from '../stores/user';
  const userStore = useUserStore();
  const isLoading = computed(() => userStore.isLoading);
</script>

<style></style>
