import axios from "axios";
import { contractId } from "../constants.js";
import { getURLFromPieces } from "../utils";
import { AUTHENTICATE_ENDPOINT, CHALLENGE_ENDPOINT } from "../constants";
import { defineStore } from "pinia";
import { useUserStore } from "./user.js";
import { WarpFactory } from "warp-contracts";

export const useContractStore = defineStore("contract", {
  state: () => {
    return {
      contractState: [],
      contractId: contractId,
      articles: [],
      warp: null,
      contract: null,
      wallet: null,
      currentAccount: {},
      isConnected: false,
      isAuthenticated: false,
      userStore: useUserStore(),
    };
  },

  getters: {
    getContractState: (state) => state.contract,
    getArticlesState: (state) => state.articles,
    getCurrentAccount: (state) => state.currentAccount,
    getIsConnected: (state) => state.isConnected,
  },

  actions: {
    setCurrentAccount(account) {
      this.currentAccount = account;
    },
    setConnectedStatus(status) {
      this.isConnected = status;
    },
    setAuthentication(authenticate) {
      this.isAuthenticated = authenticate;
    },
    async initWarp() {
      try {
        const { WarpFactory } = await import("warp-contracts");
        const environment = process.env.NODE_ENV;
        this.warp =
          environment === "development"
            ? await WarpFactory.forLocal(1984)
            : await WarpFactory.forMainnet();
      } catch (error) {
        console.error("Error initializing Warp:", error);
      }
    },

    async getContract() {
      try {
        if (!this.warp) await this.initWarp();
        this.contract = await this.warp.contract(this.contractId);
        const { cachedValue } = await this.contract.readState();
        this.articles = this.extractValidArticles(cachedValue);
      } catch (error) {
        console.error("Error fetching contract:", error);
      }
    },

    extractValidArticles(cachedValue) {
      const transactionIds = Object.keys(cachedValue.validity);
      return cachedValue.state.articles
        .map((article, index) => ({
          ...article,
          transactionId: cachedValue.validity[transactionIds[index]]
            ? transactionIds[index]
            : null,
        }))
        .filter((article) => article.transactionId !== null);
    },

    async connectArconnect() {
      try {
        this.userStore.setIsLoading(true);
        if (window.arweaveWallet) {
          await window.arweaveWallet.connect([
            "ACCESS_ADDRESS",
            "SIGNATURE",
            //"SIGN_TRANSACTION",
            //"ACCESS_ALL_ADDRESSES",
          ]);
          const account = await window.arweaveWallet.getActiveAddress();
          this.setConnectedStatus(true);
          this.currentAccount = { address: account };
          //if (!this.contract) {
          //  await this.getContract();
          //}
          //await this.contract.connect("use_wallet");
        } else {
          throw new Error(
            "ArConnect is not available. Please install the extension.",
          );
        }
      } catch (error) {
        console.error("Error connecting with ArConnect:", error);
        this.userStore.setServerError(
          error.message || "Error connecting with ArConnect",
        );
      } finally {
        this.userStore.setIsLoading(false);
      }
    },

    async connectArweaveApp() {
      try {
        this.userStore.setIsLoading(true);
        const { ArweaveWebWallet } = await import("arweave-wallet-connector");
        const arweaveWebWallet = new ArweaveWebWallet({
          name: "Karakutu",
        });
        await arweaveWebWallet.setUrl("https://arweave.app");
        await arweaveWebWallet.connect();
        this.wallet = arweaveWebWallet;
        if (arweaveWebWallet.connected) {
          this.userStore.setConnectedStatus(true);
          this.wallet = arweaveWebWallet;
          if (!this.contract) {
            await this.getContract();
          }
          await this.contract.connect("use_wallet");
          const account = arweaveWebWallet.address;
          this.userStore.currentAccount = { address: account };
        } else {
          throw new Error("Wallet connection failed");
        }
      } catch (error) {
        this.userStore.setServerError(
          error.message || "Error connecting wallet",
        );
        console.error("Error connecting wallet:", error);
      } finally {
        this.userStore.setIsLoading(false);
      }
    },

    async disconnectWallet() {
      try {
        if (window.arweaveWallet) {
          await window.arweaveWallet.disconnect();
          this.isConnected = false;
          this.currentAccount = {};
        } else {
          console.warn("ArConnect is not available.");
        }
      } catch (error) {
        console.error("Error disconnecting wallet:", error);
      }
    },

    async postArticle(payload) {
      try {
        if (!this.warp) {
          await this.initWarp();
        }
        if (!this.contract) {
          await this.getContract();
        }
        if (window.arweaveWallet) {
          const connectedAddress =
            await window.arweaveWallet.getActiveAddress();
          if (!connectedAddress) {
            throw new Error(
              "ArConnect wallet not connected. Please connect your wallet.",
            );
          }
          await this.contract.connect("use_wallet");
          const interactionData = {
            function: "postArticle",
            title: payload.title,
            newsText: payload.newsText,
            publishDate: payload.publishDate,
            newspaperName: payload.newspaperName,
            authors: payload.authors,
            columnTitle: payload.columnTitle || "",
            description: payload.description || "",
            metadataInfo: payload.metadataInfo || "{}",
          };
          const result = await this.contract.writeInteraction(interactionData);
          const transactionId = result.originalTxId;
          console.log("Transaction ID:", transactionId);
        } else {
          throw new Error(
            "Please connect with ArConnect before posting an article.",
          );
        }
      } catch (error) {
        console.error("Error posting article:", error);
      }
    },

    async getPublicJWK() {
      try {
        await window.arweaveWallet.connect(["ACCESS_PUBLIC_KEY"]);
        const publicKey = await window.arweaveWallet.getActivePublicKey();
        const publicJWK = {
          e: "AQAB", // The exponent for Arweave (standard value)
          ext: true, // Key is extractable
          kty: "RSA", // Key type for Arweave
          n: publicKey, // The modulus, which is the public key from ArConnect
        };
        return publicJWK;
      } catch (error) {
        console.error("Failed to get public key:", error);
        throw error;
      }
    },

    async fetchChallenge() {
      const response = await axios.get(getURLFromPieces(CHALLENGE_ENDPOINT), {
        params: { arweave_address: this.currentAccount.address },
      });
      const challenge = response.data.challenge;
      if (!challenge) {
        throw new Error("Failed to retrieve challenge from server.");
      }
      return challenge;
    },

    async signChallenge(challenge) {
      try {
        if (!challenge) {
          throw new Error("Challenge is undefined or null.");
        }
        const challengeBuffer = new TextEncoder().encode(challenge);
        const rawSignature =
          await window.arweaveWallet.signMessage(challengeBuffer);
        return btoa(String.fromCharCode(...new Uint8Array(rawSignature)));
      } catch (error) {
        console.error("Error signing challenge:", error);
        throw error; // Rethrow the error for further handling
      }
    },

    async verifyChallenge(challenge, signature) {
      const challengeBuffer = new TextEncoder().encode(challenge);
      return window.arweaveWallet.verifyMessage(challengeBuffer, signature);
    },

    async submitAuthentication(publicJWK, challenge, signature) {
      const authResponse = await axios.get(
        getURLFromPieces(AUTHENTICATE_ENDPOINT),
        {
          headers: {
            "X-Arweave-Address": this.currentAccount.address,
            "X-Signature": signature,
            "X-Challenge": challenge,
            "X-Public-JWK": JSON.stringify(publicJWK),
          },
        },
      );

      return (
        authResponse.status === 200 && authResponse.data.Result === "Success"
      );
    },

    async authenticateWithArconnect() {
      try {
        if (!window.arweaveWallet) {
          throw new Error(
            "ArConnect is not available. Please install the extension.",
          );
        }
        if (!this.isConnected) {
          await this.connectArconnect();
        }
        const publicJWK = await this.getPublicJWK();
        const challenge = await this.fetchChallenge();
        const signature = await this.signChallenge(challenge);
        const isValidSignature = await this.verifyChallenge(
          challenge,
          signature,
        );
        if (!isValidSignature) {
          this.isAuthenticated = false;
          return this.isAuthenticated;
        }
        const authSuccess = await this.submitAuthentication(
          publicJWK,
          challenge,
          signature,
        );
        this.isAuthenticated = authSuccess;
      } catch (error) {
        this.isAuthenticated = false;
      }
      return this.isAuthenticated;
    },

    async syncWallet() {
      if (window.arweaveWallet) {
        window.addEventListener("arweaveWalletLoaded", async () => {
          const permissions = await window.arweaveWallet.getPermissions();
          if (permissions.length <= 0) {
            await window.arweaveWallet.connect(["ACCESS_ADDRESS"]);
          }
          const account = await window.arweaveWallet.getActiveAddress();
          this.setCurrentAccount({ address: account, type: "ArConnect" });
          this.setConnectedStatus(true);
          window.arweaveWallet.on("accountChanged", (newAccount) => {
            if (!newAccount) {
              this.setConnectedStatus(false);
              this.setCurrentAccount({});
              this.setAuthenticated(false);
            } else {
              this.setCurrentAccount({
                address: newAccount,
                type: "ArConnect",
              });
              this.setAuthenticated(false);
            }
          });
        });
        window.addEventListener("walletSwitch", (e) => {
          const newAddress = e.detail.address;
          if (newAddress) {
            this.setCurrentAccount({
              address: newAccount,
              type: "ArConnect",
            });
            this.setAuthenticated(false);
          } else {
            this.setConnectedStatus(false);
            this.setCurrentAccount({});
            this.setAuthenticated(false);
          }
        });
      } else {
        console.warn("ArConnect is not available.");
      }
    },
  },
});
