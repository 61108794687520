<template>
  <div class="scroll-container">
    <div class="top-page-top" :class="{ hidden: atTop }">
      <div class="top-page-top-btn" @click="scrollToTop"></div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";

export default {
  name: "ScrollTopButton",
  setup() {
    const atTop = ref(true);

    const handleScroll = () => {
      atTop.value = window.scrollY < 50;
    };

    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
      handleScroll();
    });

    onBeforeUnmount(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      atTop,
      scrollToTop,
    };
  },
};
</script>

<style scoped>
.scroll-container {
  position: fixed;
  width: 3rem;
  top: calc(100% - 11.75rem);
  right: 6.25vw;
  z-index: 1000;
}

.top-page-top {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 5.5rem;
  right: 0;
}

.top-page-top-btn {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0);
  border: solid 1px #000;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  transition:
    width 0.4s ease,
    height 0.4s ease,
    background-color 0.4s ease,
    border-radius 0.4s ease;
}

.top-page-top-btn::before {
  content: "";
  position: absolute;
  top: 55%;
  left: 50%;
  width: 0.25rem;
  height: 0.25rem;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
  transform: translate(-50%, -50%) rotate(-45deg);
  transition:
    width 0.4s ease,
    height 0.4s ease,
    background-color 0.4s ease,
    border-radius 0.4s ease;
}

.top-page-top-btn:hover::before {
  width: 0.5rem;
  height: 0.5rem;
  animation-name: drop;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

@keyframes drop {
  0% {
    top: 80%;
    opacity: 0;
  }
  30% {
    top: 60%;
    opacity: 1;
  }
  100% {
    top: 40%;
    opacity: 0;
  }
}

.top-page-top-btn:hover {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 1);
}

.hidden {
  opacity: 0;
  pointer-events: none;
}
</style>
