<template>
  <header class="karakutu-header">
    <div class="karakutu-header-left">
      <RouterLink to="/search" class="title-link">
        <h3 class="karakutu-title">Kara kutu<span class="beta-label">beta</span></h3>
      </RouterLink>
    </div>
    <div class="karakutu-header-center">
      <SearchBar
        @select-suggestion="selectSuggestion"
      />
    </div>
    <div class="karakutu-header-right">
      <button class="menu-icon" @click="toggleMenu">
        <span class="material-symbols-outlined">menu</span>
      </button>
      <nav :class="{ 'show-menu': showMenu }">
        <router-link :to="{ name: 'archive' }" class="archive-link">
          <span class="material-symbols-outlined google-icon">inventory</span>
          {{ $t("appHeader.archive") }}
        </router-link>
        <LanguageButton />
        <ConnectButton />
      </nav>
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
import { useRouter } from "vue-router";
import SearchBar from "./SearchBar.vue";
import LanguageButton from "./LanguageButton.vue";
import ConnectButton from "./ConnectButton.vue";

export default {
  name: "AppHeader",
  components: {
    ConnectButton,
    LanguageButton,
    SearchBar,
  },
  setup() {
    const searchQuery = ref("");
    const router = useRouter();
    const selectSuggestion = (suggestion) => {
      const routeParams = { id: suggestion.id };
      router.push({ name: "haber", params: routeParams });
    };
    const showMenu = ref(false);
    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    return {
      searchQuery,
      selectSuggestion,
      showMenu,
      toggleMenu,
    };
  },
};
</script>

<style scoped>

.beta-label {
  font-size: 0.64rem;
  line-height: 0.8rem;
  font-optical-sizing: true;
  font-weight: 600;
  vertical-align: super;
}
.archive-link{
  background-color: transparent;
  color: rgb(34,35,38);
  cursor: pointer;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
  gap: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}
.google-icon {
  color: rgb(34,35,38);
  font-size: 1.25rem;
  font-weight: 200;
}

.archive-link {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: rgb(34, 35, 38);
  cursor: pointer;
  font-size: 1rem;
  gap: 0.25rem;
  padding: 0 0.5rem;
  line-height: 1.5rem;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}


.title-link {
  text-decoration: none;
}

nav {
  display: flex;
  align-items: center;
}


@media (max-width: 1280px) {
  .menu-icon {
    display: inline-flex;
    font-size: 1.5rem;
    color: rgb(34, 35, 38);
  }

  .karakutu-header-right nav {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 8px;
    z-index: 10;
  }

  .karakutu-header-right nav.show-menu {
    display: flex;
  }

  .archive-link,
  .google-icon,
  .beta-label {
    font-size: 0.9rem;
  }
}

@media (max-width: 720px) {
  .karakutu-header-center {
    display: none;
  }
  .karakutu-header-right{
    width: fit-content;
  }
  .karakutu-header-left{
    width: fit-content;
  }
}
</style>
