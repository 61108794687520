import { ref } from "vue";
import axios from "axios";
import DOMPurify from "dompurify";
import { useI18n } from "vue-i18n";
import { months } from "./constants";
import { LANGUAGE_MAP } from "./constants";

const API_BASE_URL = import.meta.env.VITE_APP_API_URL;

export const getURLFromPieces = (...args) => {
  const baseUrl = import.meta.env.VITE_APP_API_URL;
  return new URL(args.join("/"), baseUrl).toString();
};

export const fetchNewsDetails = async (newsId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/newsdetails/${newsId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching news details:", error);
    throw new Error("Failed to fetch news details");
  }
};

export const fetchNewsVersions = async (newsId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/news/${newsId}/versions`);
    return response.data;
  } catch (error) {
    console.error("Error fetching news versions:", error);
    throw new Error("Failed to fetch news versions");
  }
};

export const getNewsSummary = () => {
  const newsSummary = ref([]);
  const error = ref(null);
  const load = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/news-summary`);
      if (!response.ok) {
        throw Error("An error occurred. Please try again.");
      }
      newsSummary.value = await response.json();
    } catch (err) {
      error.value = err.message;
      console.error("Error fetching news summary:", error);
    }
  };

  return { newsSummary, error, load };
};

export async function fetchNewslinkStats() {
  const response = await fetch(`${API_BASE_URL}/newslink-stats`);
  if (!response.ok) {
    throw new Error("Failed to fetch news link stats");
  }
  const data = await response.json();
  return data;
}
export async function fetchChangedNews() {
  try {
    const response = await fetch(`${API_BASE_URL}/changed-newsdetails`);
    if (!response.ok) {
      throw new Error("Failed to fetch changed news details");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching changed news details:", error);
    throw error;
  }
}
export async function fetchErrorReports() {
  try {
    const response = await fetch(`${API_BASE_URL}/error-reports`);
    if (!response.ok) {
      throw new Error("Failed to fetch error reports");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching error reports:", error);
    throw error;
  }
}
export async function fetchAuthors() {
  const response = await fetch(`${API_BASE_URL}/authors`);
  if (!response.ok) {
    throw new Error("Failed to fetch authors");
  }
  const data = await response.json();
  return data;
}
export async function fetchAuthorDetails(authorID) {
  try {
    const response = await fetch(
      `${API_BASE_URL}/authors/${authorID}/news-links`,
    );
    if (!response.ok) {
      throw new Error(
        `Failed to fetch details for author: ${response.status} ${response.statusText}`,
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(`Error fetching details for author :`, error);
    throw error;
  }
}

export function formatDateToElastic(dateString) {
  if (!dateString) {
    console.error("Invalid date string provided.");
    return null;
  }
  const isoDate = new Date(dateString);
  return isoDate.toISOString().split("T")[0];
}

export function formatToISODate(dateString) {
  if (!dateString) {
    console.error("Invalid date string provided.");
    return null;
  }
  const isoPattern =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/;
  const dateTimePattern =
    /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}(:\d{2})?([+-]\d{2}:\d{2})?$/;
  if (isoPattern.test(dateString)) {
    const isoDate = new Date(dateString);
    if (isNaN(isoDate.getTime())) {
      console.error("Invalid ISO date string provided.");
      return null;
    }
    return {
      date: isoDate.toISOString().split("T")[0],
      time: isoDate.toISOString().split("T")[1].split("+")[0].slice(0, 5),
    };
  }
  if (dateTimePattern.test(dateString)) {
    const [datePart, timePart] = dateString.split(" ");
    const isoDate = new Date(`${datePart}T${timePart}`);
    if (isNaN(isoDate.getTime())) {
      console.error("Invalid date string provided.");
      return null;
    }
    return {
      date: isoDate.toISOString().split("T")[0],
      time: isoDate.toISOString().split("T")[1].split("+")[0].slice(0, 5),
    };
  }
  for (const [monthName, monthNumber] of Object.entries(months)) {
    if (dateString.includes(monthName)) {
      dateString = dateString.replace(monthName, monthNumber);
      break;
    }
  }

  const dateParts = dateString.split(" ").filter((part) => part);
  let time = "00:00";
  const timePattern = /^\d{1,2}:\d{2}/;
  if (timePattern.test(dateParts[0])) {
    time = dateParts.shift();
  }

  const [day, month, year] = dateParts;
  if (!day || !month || !year) {
    console.error("Invalid natural language date string provided:", dateString);
    return null;
  }
  const isoDate = `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  return { date: isoDate, time: time };
}
export function formatDate(timestamp) {
  const { locale } = useI18n();
  let datePart, timePart;

  if (typeof timestamp === "string") {
    timestamp = timestamp.trim();
    if (timestamp.startsWith("0000-00-00T00:00:00")) {
      return { datePart: "0000-00-00", timePart: "00:00:00" };
    }
    if (/^\d{4}-\d{2}-\d{2}$/.test(timestamp)) {
      timestamp += "T00:00:00+03:00";
    }
    const isoPattern =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?(Z|[+-]\d{2}:\d{2})?$/;
    if (isoPattern.test(timestamp)) {
      const [dateStr, timeWithOffset] = timestamp.split("T");
      const [timeStr] = timeWithOffset.split(/[+-]/);
      datePart = new Date(dateStr).toLocaleDateString(locale.value, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      timePart = timeStr.slice(0, 5);
    } else {
      const { date: isoDate, time: formattedTimePart } =
        formatToISODate(timestamp);
      if (!isoDate) {
        console.error("Invalid date conversion:", timestamp);
        return { datePart: "Invalid Date", timePart: "" };
      }
      datePart = new Date(isoDate).toLocaleDateString(locale.value, {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      timePart = formattedTimePart ? formattedTimePart.slice(0, 5) : "00:00";
    }
  } else if (typeof timestamp === "number" && !isNaN(timestamp)) {
    const date = new Date(timestamp * 1000);
    if (isNaN(date.getTime())) {
      console.error("Invalid Date object created:", timestamp, date);
      return { datePart: "Invalid Date", timePart: "" };
    }
    datePart = date.toLocaleDateString(locale.value, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    timePart = date.toLocaleTimeString(locale.value, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  } else {
    console.error("Invalid timestamp:", timestamp);
    return { datePart: "Invalid Date", timePart: "" };
  }
  return { datePart, timePart };
}

export function getLanguageName(key) {
  return LANGUAGE_MAP[key] || key;
}

export function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/ğ/g, "g")
    .replace(/ç/g, "c")
    .replace(/ş/g, "s")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ü/g, "u")
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export function cleanupLines(str) {
  str = str.replace(/\n/g, "");
  return str;
}

export function decodeHtmlEntities(str) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = str;
  return textArea.value;
}

export function cleanupDiffHtml(str) {
  return str.replace(/¶/g, "").replace(/\n/g, "<br>");
}

export function throttle(func, limit) {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
}

export const sanitizeHtml = (html) => {
  decodeHtmlEntities(html);
  let sanitizedHtml = DOMPurify.sanitize(html, {
    FORBID_TAGS: ["script", "object", "embed"],
    FORBID_ATTR: [
      "style",
      "onerror",
      "onclick",
      "onload",
      "srcset",
      "data-src",
    ],
  });
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = sanitizedHtml;
  tempDiv.querySelectorAll("iframe").forEach((iframe) => {
    const src = iframe.getAttribute("src");
    if (
      src &&
      (src.includes("dailymotion.com") || src.includes("youtube.com"))
    ) {
      const parent = iframe.parentElement;
      parent.remove();
      iframe.remove();
    }
  });
  tempDiv.querySelectorAll("img").forEach((img) => {
    img.removeAttribute("width");
    img.removeAttribute("height");
  });
  tempDiv.innerHTML = tempDiv.innerHTML.replace(/\n/g, "");
  return tempDiv.innerHTML;
};

export const loadTwitterScript = () => {
  if (!window.twttr) {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    document.head.appendChild(script);
  } else {
    window.twttr.widgets.load();
  }
};
