export default {
  appHeader: {
    archive: "Archiv",
  },
  connectButton: {
    connect: "Wallet verbinden",
  },
  search: {
    placeholder: "Suche",
    searching: "Suche...",
    matchQuery: "Es wurden {total} Ergebnisse für {query} gefunden.",
    match: "{total} Ergebnisse gefunden.",
    noMatch: "Keine Ergebnisse gefunden.",
    sort: "Sortieren",
    filter: {
      title: "Suche filtern",
      author: "Autor",
      newspaper: "Zeitung",
      status: "Status",
      locations: "Wo?",
      persons: "Wer?",
      organizations: "Organisationen",
      startDate: "Startdatum",
      endDate: "Enddatum",
      apply: "Filter anwenden",
      all: "Alle",
      daterange: "Datumsbereich",
    },
    loading: "Suche...",
  },
  sort: {
    relevance: "Relevanz",
    date_desc: "Neueste zuerst",
    date_asc: "Älteste zuerst",
  },
  haber: {
    recordSource: "Quellenlink:",
    recordDate: "Aufnahmedatum:",
    recordStatus: "Aufnahmestatus:",
    category: "Kategorie:",
    updated: "Aktualisiert:",
    tags: "Schlagwörter:",
    oldRecords: "Frühere Aufnahmen",
    compareRecords: "Aufnahmen Vergleichen",
    compare: "Vergleichen",
    archive: "Nachricht Archivieren",
    otherNews: "Andere Artikel des Autors",
  },
};
