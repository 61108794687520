import { createRouter, createWebHashHistory } from "vue-router";
import DefaultLayout from "../layouts/DefaultLayout.vue";
import AdminLayout from "../layouts/AdminLayout.vue";
import NotFoundView from "../views/NotfoundView.vue";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      meta: { layout: DefaultLayout },
      children: [
        {
          path: "",
          name: "home",
          component: () => import("../views/SearchView.vue"),
        },
        {
          path: "archive",
          name: "archive",
          component: () => import("../views/ArchiveView.vue"),
        },
        {
          path: "/archive/:id",
          name: "ArticleDetail",
          component:
            process.env.NODE_ENV === "production"
              ? null
              : () => import("../views/ArticleDetail.vue"),
          props: true,
        },
        {
          path: "search",
          name: "search",
          component: () => import("../views/SearchView.vue"),
        },
        {
          path: ":id(\\d+)",
          name: "haber",
          component: () => import("../views/HaberView.vue"),
          props: true,
        },
      ],
    },
    //{
    //  //Test routes
    //  path: "/test",
    //  meta: { layout: GridLayout },
    //  name: "test",
    //  component: () => import("../views/TestView.vue"),
    //},
    {
      // Admin routes
      path: "/admin",
      meta: { layout: AdminLayout },
      children: [
        {
          path: "",
          name: "admin",
          component: () => import("../views/AdminView.vue"),
        },
      ],
    },
    {
      //catchall 404
      path: "/:catchAll(.*)",
      name: "NotFound",
      component: NotFoundView,
      meta: {
        layout: DefaultLayout,
      },
    },
  ],
});

export default router;
