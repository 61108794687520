<template>
  <div class="language-switcher" ref="dropdown">
    <div class="selected-language" @click="toggleDropdown">
      <span class="material-symbols-outlined">language</span>
      {{ languages[selectedLanguageIndex].code }}
      <span
        class="material-symbols-outlined arrow"
        :class="{ open: isOpen }">
        keyboard_arrow_down
      </span>
    </div>
    <div v-if="isOpen" class="dropdown-options">
      <div
        v-for="(language, index) in languages"
        :key="language.code"
        @click="selectLanguage(index)"
        class="dropdown-option"
      >
        <img :src="language.flag" :alt="language.name" class="flag" />
        {{ language.code }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onBeforeUnmount, ref } from "vue";
import { useI18n } from "vue-i18n";
import flagDE from "../assets/flags/de.svg";
import flagEN from "../assets/flags/gb.svg";
import flagTR from "../assets/flags/tr.svg";

export default defineComponent({
  setup() {
    const { locale } = useI18n({ useScope: "global" });
    const languages = [
      { code: "de", name: "Deutsch", flag: flagDE },
      { code: "en", name: "English", flag: flagEN },
      { code: "tr", name: "Türkçe", flag: flagTR },
    ];
    const selectedLanguageIndex = ref(
      languages.findIndex((lang) => lang.code === locale.value),
    );
    const isOpen = ref(false);
    const dropdown = ref(null);

    function toggleDropdown() {
      isOpen.value = !isOpen.value;
    }

    function selectLanguage(index) {
      selectedLanguageIndex.value = index;
      locale.value = languages[index].code;
      localStorage.setItem("selectedLanguage", languages[index].code);
      isOpen.value = false;
    }

    function handleClickOutside(event) {
      if (dropdown.value && !dropdown.value.contains(event.target)) {
        isOpen.value = false;
      }
    }

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      languages,
      selectedLanguageIndex,
      isOpen,
      dropdown,
      toggleDropdown,
      selectLanguage,
    };
  },
});
</script>

<style scoped>
.language-switcher {
  display: flex;
  justify-content: space-around;
  cursor: default;
  align-items: center;
  position: relative;
}

.selected-language {
  background-color: transparent;
  color: rgb(34,35,38);
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.5rem;
  font-size: 1rem;
  font-family: "Alegreya", serif;
  font-weight: 400;
  gap: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
}

.arrow {
  transition: transform 0.3s ease;
  margin-left: -0.25rem;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  width: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-option {
  padding: 0.25rem 0.4rem;
  cursor: pointer;
  font-family: "Alegreya", serif;
  line-height: 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.dropdown-option + .dropdown-option {
  margin-top: 0.25rem;
}
.dropdown-option:hover {
  background-color: #f0f0f0;
}

.flag {
  height: 1.25rem;
}
</style>
