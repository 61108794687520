import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./css/main.css";
import i18n from "./i18n/i18n.js";
import { createPinia, acceptHMRUpdate } from "pinia";

const app = createApp(App);
const pinia = createPinia();
app.use(router);
app.use(i18n);
app.use(pinia);
app.mount("#app");

if (import.meta.hot) {
  import.meta.hot.accept(() => {
    import.meta.hot.accept(acceptHMRUpdate(pinia, import.meta.hot));
  });
}
