export const contractId = "NG4dnwwJR1yxuoJudiGko3an5_LeAuOKasY5JCJnbFc";

export const ALL_AUTHORS_ENDPOINT = "allauthors";
export const ALL_NEWSPAPERS_ENDPOINT = "allnewspapers";
export const ALL_ENTITIES_ENDPOINT = "entities";
export const PAGINATED_AUTHORS_ENDPOINT = "search-authors";
export const SEARCH_ENDPOINT = "search";
export const SUGGEST_ENDPOINT = "suggest";
export const TOTAL_LINKS_ENDPOINT = "total-newslinks";
export const AUTHENTICATE_ENDPOINT = "authenticate";
export const CHALLENGE_ENDPOINT = "login";
export const DEADLINKS_ENDPOINT = "deadlinks";
export const CHALLENGE_KEY = "X-Challenge";
export const SIGNATURE_KEY = "X-Signature";

export const CHALLENGE_EXPIRED = "Challenge expired";

export const months = {
  Ocak: "01",
  Şubat: "02",
  Mart: "03",
  Nisan: "04",
  Mayıs: "05",
  Haziran: "06",
  Temmuz: "07",
  Ağustos: "08",
  Eylül: "09",
  Ekim: "10",
  Kasım: "11",
  Aralık: "12",
  January: "01",
  February: "02",
  March: "03",
  April: "04",
  May: "05",
  June: "06",
  July: "07",
  August: "08",
  September: "09",
  October: "10",
  November: "11",
  December: "12",
  Rêbendan: "01",
  RÊBENDAN: "01",
  Çele: "01",
  ÇELE: "01",
  Çile: "01",
  ÇİLE: "01",
  Reşemî: "02",
  REŞEMÎ: "02",
  Sibate: "02",
  SİBATE: "02",
  Adare: "03",
  ADARE: "03",
  Adar: "03",
  ADAR: "03",
  Avdar: "03",
  AVDAR: "03",
  Nîsane: "04",
  NÎSANE: "04",
  Avrêl: "04",
  AVRÊL: "04",
  Gulane: "05",
  GULANE: "05",
  Gulan: "05",
  GULAN: "05",
  Hezîrane: "06",
  HEZÎRANE: "06",
  Pûşper: "06",
  PÛŞPER: "06",
  Temmuze: "07",
  TEMMUZE: "07",
  Tîrmeh: "07",
  TÎRMEH: "07",
  Tebaxe: "08",
  TEBAXE: "08",
  Gelawêj: "08",
  GELAWÊJ: "08",
  Êlule: "09",
  ÊLULE: "09",
  Rezber: "09",
  REZBER: "09",
  Êlûn: "09",
  ÊLÛN: "09",
  Îlon: "09",
  ÎLON: "09",
  "Teşrîna verêne": "10",
  "TEŞRÎNA VERÊNE": "10",
  Kewçêr: "10",
  KEWÇÊR: "10",
  Cotmeh: "10",
  COTMEH: "10",
  "Teşrîna peyêne": "11",
  "TEŞRÎNA PEYÊNE": "11",
  Sermawez: "11",
  SERMAWEZ: "11",
  Mijdar: "11",
  MİJDAR: "11",
  Kanûne: "12",
  KANÛNE: "12",
  Berfanbar: "12",
  BERFANBAR: "12",
  Kanûn: "12",
  KANÛN: "12",
  "Kanûna Peşîn": "12",
  "KANÛNA PEŞÎN": "12",
};

export const STATUSES = [
  "Initial",
  "Error",
  "DeadLink",
  "Changed",
  "Redirected",
];

export const SORT_OPTIONS = [
  { value: "relevance", labelKey: "sort.relevance" },
  { value: "date_desc", labelKey: "sort.date_desc" },
  { value: "date_asc", labelKey: "sort.date_asc" },
];

export const INITIAL_FILTERS = {
  title: "",
  column_title: "",
  description: "",
  news_text: "",
  language_code: "",
  newspaper: "",
  author: "",
  status: "",
  publish_date_start: "1995-01-01",
  publish_date_end: new Date().toISOString().slice(0, 10),
  query: "",
  page: 0,
  sort: "date_desc",
  locations: "",
  persons: "",
  organizations: "",
};

export const LANGUAGE_MAP = {
  tur: "Türkçe",
  eng: "İngilizce",
  deu: "Almanca",
  kmr: "Kurmancî",
  kiu: "Kirmanjkî",
};
