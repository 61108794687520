<template>
  <div class="search-wrapper" ref="searchWrapper">
    <input class="search-input" type="text" :placeholder="$t('search.placeholder')" v-model="filters.query"
      @input="fetchSuggestionsData" @keyup.enter="performSearch" />
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" stroke="currentColor"
      stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-search"
      viewBox="0 0 24 24">
      <defs></defs>
      <circle cx="11" cy="11" r="8"></circle>
      <path d="M21 21l-4.35-4.35"></path>
    </svg>
    <ul v-if="showDropdown && suggestions.length > 0" class="suggestions-list">
      <li v-for="suggestion in suggestions" :key="suggestion" @click="selectSuggestion(suggestion)">
        {{ suggestion.text }}
      </li>
    </ul>
  </div>
</template>
<script>
import { defineComponent, onMounted, onUnmounted, ref, computed } from "vue";
import { useSearchStore } from "../stores/search";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "SearchBar",
  setup(_, { emit }) {
    const store = useSearchStore();
    const filters = computed(() => store.filters);
    const suggestions = computed(() => store.suggestions);
    const showDropdown = ref(false);
    const searchWrapper = ref(null);
    const router = useRouter();
    const route = useRoute();

    const performSearch = () => {
      if (filters.value.query.trim()) {
        const trimmedQuery = filters.value.query.trim();
        if (trimmedQuery.length === 0 || trimmedQuery === route.query.q) {
          return;
        }
        if (route.name !== "search") {
          router.push({
            name: "search",
            query: { q: trimmedQuery }
          });
          window.scrollTo(0, 0);
        } else {
          router.replace({
            query: {
              ...router.currentRoute.value.query,
              q: trimmedQuery,
            },
          });
          window.scrollTo(0, 0);
          store.applyFilters({ query: trimmedQuery });
        }
      }
      showDropdown.value = false;
    };

    const fetchSuggestionsData = async () => {
      if (filters.value.query.trim()) {
        if (filters.value.query.trim().length === 0) {
          store.setSuggestions([]);
          showDropdown.value = false;
          return;
        }
        try {
          await store.fetchSuggestions();
          showDropdown.value = true;
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        store.setSuggestions([]);
        showDropdown.value = false;
      }
    };

    const closeDropdown = () => {
      showDropdown.value = false;
    };

    const handleClickOutside = (event) => {
      if (searchWrapper.value && !searchWrapper.value.contains(event.target)) {
        closeDropdown();
      }
    };

    const selectSuggestion = (suggestion) => {
      emit("select-suggestion", suggestion);
      closeDropdown();
    };

    onMounted(() => {
      document.addEventListener("click", handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickOutside);
    });

    return {
      filters,
      searchWrapper,
      performSearch,
      fetchSuggestionsData,
      selectSuggestion,
      closeDropdown,
      showDropdown,
      suggestions
    };
  },
});
</script>

<style scoped>
.search-wrapper {
  position: relative;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-radius: 1rem;
  background-color: white;
  border: 1px solid #ddd;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 100000;
  padding: 0.75rem;
}

.suggestions-list li {
  cursor: pointer;
}

.suggestions-list li+li {
  padding: 0.375rem 0;
  border-top: 0.1px solid #4a4a4a40;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}
</style>