<template>
  <div class="overlay">
    <dialog open class="centered-dialog">
      <div class="dialog-card">
        <div class="dialog-header">
          <h3>Connect Your Wallet</h3>
          <button @click="closeDialog" class="closer">×</button>
        </div>
        <div class="dialog-item">
          <button class="connect-button" @click="connectArConnect">
            <img src="../assets/wallets/ArConnect.svg" alt="ArConnect Logo" class="logo" />
            <h4>Connect via ArConnect</h4>
          </button>
          <button class="connect-button" @click="connectArweaveApp">
            <img src="../assets/wallets/Arweave.svg" alt="ArConnect Logo" class="logo" />
            <h4>Connect using Arweave.app</h4>
          </button>
        </div>
        <div class="dialog-item no-wallets">
          No wallets? <a href="https://docs.arweave.org/developers/wallets/generating-cold-wallet" target="_blank" rel="noopener noreferrer" class="wallet-link">How to generate a wallet</a>
        </div>
      </div>
    </dialog>
  </div>
</template>

  <script>
  import { useContractStore } from "../stores/contract";
  import { defineComponent, ref } from "vue";

  export default defineComponent({
    setup() {
      const contractStore = useContractStore();
      const dialogVisible = ref(true);
      const connectArConnect = async () => {
        await contractStore.connectArconnect();
        dialogVisible.value = false;
      };

      const connectArweaveApp = async () => {
        await contractStore.connectArweaveApp();
        dialogVisible.value = false;
      };

      const closeDialog = () => {
        dialogVisible.value = false;
      };

      return {
        dialogVisible,
        connectArConnect,
        connectArweaveApp,
        closeDialog,
      };
    },
  });
  </script>

<style scoped>


/* Button styles */
.connect-button {
  display: flex;
  align-content: center;
  padding: .5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  gap: 1rem;
}

.connect-button:hover {
  background-color: #f7f7f9;
}

.logo {
  width: 1.5rem;
  height: 1.5rem;
}

.no-wallets {
  margin-top: 1rem; /* Add some space above */
  font-size: 0.9rem; /* Slightly smaller font size */
  color: #555; /* Softer color for text */
}
.wallet-link {
  color: #007bff; /* Blue color for the link */
  text-decoration: underline; /* Underline for better visibility */
}

.wallet-link:hover {
  color: #0056b3; /* Darker blue on hover */
}
</style>
