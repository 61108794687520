<template>
  <footer class="app-footer">
    <div class="app-footer-left">
      <small>Content is archived and republished uncensored, without publisher permission. Responsibility for inaccuracies lies with original publishers. Managed with oversight by a decentralized system for public access.</small>
    </div>
    <div class="app-footer-right">
      <a href="https://twitter.com/" target="_blank">
        <img :src="twitter" alt="Twitter" class="footer-icon" />
      </a>
      <a href="https://telegram.me/" target="_blank">
        <img :src="telegram" alt="Telegram" class="footer-icon" />
      </a>
    </div>
  </footer>
</template>

<script>
import telegram from "../assets/footer/telegram.svg";
import twitter from "../assets/footer/x.svg";

export default {
  name: "AppFooter",
  setup() {
    return {
      telegram,
      twitter,
    };
  },
};
</script>

<style scoped>
.app-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0;
  bottom: 0;
	z-index: 8;
  min-height: 4.5rem;
  background-color: black;
  color: white;
  flex-wrap: wrap;
}
.app-footer-left{
	display: flex;
	align-items: center;
  max-width:70%;
}

.app-footer-right{
	display: flex;
	align-items: center;
}

.app-footer-left > small {
  font-weight: 100;
  margin: .75rem 0;
}

.app-footer-left{
  padding-left: 6.25%;
}

.app-footer-right{
  justify-content: flex-end;
  padding-right: 6.25%;
}
.footer-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: .75rem;
  z-index: 10;
}
.github-icon {
  filter: invert(1);
}

@media (max-width: 720px) {

  .app-footer-left{
    padding: 0 6.25%;
    max-width: 100%;
  }

  .app-footer-right{
    padding: 0 6.25%;
    max-width: 100%;
    width: 100%;
    align-items: flex-end;
  }

}


</style>
