import axios from "axios";
import { defineStore } from "pinia";
import { DEADLINKS_ENDPOINT } from "../constants";
import { getURLFromPieces } from "../utils";

export const useUserStore = defineStore("user", {
  state: () => ({
    isLoading: false,
    serverError: null,
  }),

  getters: {
    getIsLoading: (state) => state.isLoading,
    getServerError: (state) => state.serverError,
  },

  actions: {
    setIsLoading(status) {
      this.isLoading = status;
    },
    setServerError(error) {
      this.serverError = error;
    },
    resetServerError() {
      this.serverError = null;
    },

    async fetchDeadlinks() {
      this.setIsLoading(true);
      try {
        const response = await axios.get(getURLFromPieces(DEADLINKS_ENDPOINT));
        return response.data;
      } catch (error) {
        console.error("Error fetching news details:", error);
        throw new Error("Failed to fetch news details");
      } finally {
        this.setIsLoading(false);
      }
    },

    async fetchNewsDetails(newsId) {
      this.setIsLoading(true);
      try {
        const response = await axios.get(
          getURLFromPieces(`newsdetails/${newsId}`),
        );
        return response.data;
      } catch (error) {
        console.error("Error fetching news details:", error);
        throw new Error("Failed to fetch news details");
      } finally {
        this.setIsLoading(false);
      }
    },
  },
});
