<template>
  <div class="not-found">
    <div class="content">
      <h3 class="title">Page Not Found</h3>
      <p class="description">
        The page you’re looking for doesn’t exist or may have been entered incorrectly. Please check the URL or return to the homepage.
      </p>
      <p class="thanks">
        We appreciate your visit and hope you find what you're looking for. If you need help, please contact the developers.
      </p>
      <div class="actions">
        <router-link to="/" class="home-button">Go to Homepage</router-link>
        <router-link to="/search" class="search-button">Search</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>

<style scoped>
.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 9rem);
  color: #333;
  font-family: 'Alegreya', serif;
}

.content {
  text-align: center;
  max-width: 22rem;
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.title {
  color: #222326;
  margin-bottom: 1rem;
}

.description, .thanks {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  color: #4A4A4A;
}

.actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.home-button, .search-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: #fff;
  background-color: #222326;
  text-decoration: none;
  transition: background-color 0.3s;
}

.home-button:hover, .search-button:hover {
  background-color: #333;
}

@media (max-width: 768px) {
  .content {
    padding: 1.5rem;
    max-width: 90%;
  }
}
</style>
