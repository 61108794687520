import { createI18n } from "vue-i18n";
import en from "./en";
import de from "./de";
import tr from "./tr";

const i18n = createI18n({
  legacy: false,
  locale: "tr",
  fallbackLocale: "en",
  globalInjection: true,
  messages: {
    de,
    en,
    tr,
  },
});

export default i18n;
