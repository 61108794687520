export default {
  appHeader: {
    archive: "Archive",
  },
  connectButton: {
    connect: "Connect Wallet",
  },
  search: {
    placeholder: "Search",
    searching: "Searching...",
    matchQuery: "Found {total} results matching {query}.",
    match: "Found {total} results.",
    noMatch: "No results found.",
    sort: "Sort",
    filter: {
      title: "Filter your search",
      author: "Author",
      newspaper: "Newspaper",
      status: "Status",
      locations: "Where?",
      persons: "Who?",
      organizations: "Organizations",
      startDate: "Start Date",
      endDate: "End Date",
      apply: "Apply Filter",
      all: "All",
      daterange: "Date Range",
    },
    loading: "Searching...",
  },
  sort: {
    relevance: "Relevance",
    date_desc: "Newest",
    date_asc: "Oldest",
  },
  haber: {
    recordSource: "Source Link:",
    recordDate: "Record Date:",
    recordStatus: "Record Status:",
    category: "Category:",
    updated: "Updated:",
    tags: "Tags:",
    oldRecords: "Past Records",
    compareRecords: "Compare Records",
    compare: "Compare",
    archive: "Archive the News",
    otherNews: "Other Articles by the Author",
  },
};
