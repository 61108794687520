<template>
  <div class="app-container">
    <div id="blurry-filter"></div>
    <AppHeader></AppHeader>
    <slot></slot>
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
export default {
  components: { AppHeader },
  name: "AdminLayout",
};
</script>

<style scoped></style>
