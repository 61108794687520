export default {
  appHeader: {
    archive: "Arşiv",
  },
  connectButton: {
    connect: "Cüzdanı Bağla",
  },
  search: {
    placeholder: "Araştır",
    searching: "Aranıyor...",
    matchQuery: "Aramanız {query} ile eşleşen {total} sonuç bulundu.",
    match: "{total} sonuç bulundu.",
    noMatch: "Hiç sonuç bulunamadı.",
    sort: "Sıralama",
    filter: {
      title: "Aramanı filtrele",
      author: "Yazar",
      newspaper: "Gazete",
      status: "Kayıt Durumu",
      locations: "Nerede?",
      persons: "Kim?",
      organizations: "Kurumlar",
      startDate: "Başlangıç tarihi",
      endDate: "Bitiş tarihi",
      apply: "Filtreyi uygula",
      all: "Hepsi",
      more: "Daha",
      daterange: "Tarih aralığı",
    },
    loading: "Aranıyor...",
  },
  sort: {
    relevance: "Alaka",
    date_desc: "En Yeni",
    date_asc: "En Eski",
  },
  haber: {
    recordSource: "Kaynak Link:",
    recordDate: "Kayıt tarihi:",
    recordStatus: "Kayıt durumu:",
    category: "Kategori:",
    updated: "Güncelleme:",
    tags: "Etiketler:",
    oldRecords: "Geçmiş Kayıtlar",
    compareRecords: "Kayıtları Karşılaştır",
    compare: "Karşılaştır",
    archive: "Haberi Arşivle",
    otherNews: "Yazarın Diğer Yazıları",
  },
};
